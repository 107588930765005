import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index'

Vue.use(VueRouter);

const routes = [
  // 移动端路由跳转
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "LogIn",
    component: () => import("@/views/LogIn.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/SignUp.vue"),
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: () => import("@/views/ForgetPassword.vue"),
  },
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: () => import("@/views/ChangePassword.vue"),
  },
  {
    path: "/AccountCode",
    name: "AccountCode",
    component: () => import("@/views/AccountCode.vue"),
  },
  {
    path: "/FindByAccountCode",
    name: "FindByAccountCode",
    component: () => import("@/views/FindByAccountCode.vue"),
  },
  {
    path: "/Recharge",
    name: "Recharge",
    component: () => import("@/views/Recharge.vue"),
  },
  {
    path: "/Order",
    name: "Order",
    component: () => import("@/views/Order.vue"),
  },
  {
    path: "/CooperateUs",
    name: "CooperateUs",
    component: () => import("@/views/CooperateUs.vue"),
  },

  {
    path: "/Chat",
    name: "Chat",
    component: () => import("@/views/Chat.vue"),
  },
  {
    path: "/Teamwork",
    name: "Teamwork",
    component: () => import("@/views/Teamwork.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: () => import("@/views/FAQ.vue"),
  },
  {
    path: "/ProblemFeedback",
    name: "ProblemFeedback",
    component: () => import("@/views/ProblemFeedback.vue"),
  },
  {
    path: "/UserInfo",
    name: "UserInfo",
    component: () => import("@/views/UserInfo.vue"),
  },
  {
    path: "/NewChat",
    name: "NewChat",
    component: () => import("@/views/NewChat.vue"),
  },
  {
    path: "/TOS",
    name: "TOS",
    component: () => import("@/views/TOS.vue"),
  },
  {
    path: "/Privacy",
    name: "Privacy",
    component: () => import("@/views/Privacy.vue"),
  },
  {
    path: "/ActivityCenter",
    name: "ActivityCenter",
    component: () => import("@/views/ActivityCenter.vue"),
  },
  {
    path: "/wxQRcode",
    name: "wxQRcode",
    component: () => import("@/views/wxQRcode.vue"),
  },
  {
    path: "/zfbQRcode",
    name: "zfbQRcode",
    component: () => import("@/views/zfbQRcode.vue"),
  },
  {
    path: "/GameDetails",
    name: "GameDetails",
    component: () => import("@/views/GameDetails.vue"),
  },
  {
    path: "/Booking",
    name: "Booking",
    component: () => import("@/views/Booking.vue"),
  },

];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});


export default router;
