<template>
  <div class="ChangeLanguage">
    <v-menu offset-y :rounded="rounded">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon>mdi-earth</v-icon>
          {{ locales.filter((c) => c.value == locale)[0].text }}
        </span>
      </template>
      <v-list>
        <v-list-item
          style="min-height: 35px"
          link
          v-for="(item, index) in locales"
          :key="index"
          @change="changeLanuage(item)"
        >
          <v-list-item-title style="font-size: 16px!important">{{
            item.text
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rounded: "lg",
      locale: "zh-TW", //使用i18n实例默认定义的语言
      locales: [
        {
          value: "en-US",
          text: "English",
        },
        {
          value: "zh-TW",
          text: "中文繁體",
        },
        {
          value: "vi-VN",
          text: "Việt Nam",
        },
        // {
        //   value: "th-TH",
        //   text: "Thái",
        // },
        {
          value: "ko",
          text: "한국어",
        },
      ],
    };
  },
  created() {
    var lang = localStorage.getItem("locale");
    this.locale = lang;
    this.$i18n.locale = lang;
  },
  methods: {
    changeLanuage(val) {
      this.locale = val.value;
      this.$i18n.locale = val.value;
      localStorage.setItem("locale", val.value);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.ChangeLanguage {
  margin-left: 10px;
  display: flex;
  align-items: center;
  .v-icon {
    color: #fff;
  }
  span {
    display: inline-block;
    font-size: 0.37333rem;
    line-height: 0.8rem;
    height: 0.8rem;
    text-align: center;
    background-color: #797979;
    color: #fff;
    border-radius: 0.53333rem;
    padding: 0 .4rem;
  }
}
</style>