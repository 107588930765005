import Vue from "vue";
import VueI18n from "vue-i18n";

// 导入语言包信息
import EN from "./locale/en-US.json";
import TW from "./locale/zh-TW.json";
import VI from "./locale/vi-VN.json";
import TH from "./locale/th-TH.json";
import KR from "./locale/ko.json";

// 注册i18n
Vue.use(VueI18n);

// 语言包根据语言环境分类
const messages = {
  "en-US": EN,
  "zh-TW": TW,
  "vi-VN": VI,
  "th-TH": TH,
  "ko": KR,
};

if (!localStorage.getItem("locale")) {
  if (navigator.language == "zh-TW") {
    localStorage.setItem("locale", "zh-TW");

  } else if (navigator.language == "en") {
    localStorage.setItem("locale", "en-US");

  } else if (navigator.language == "vi") {
    localStorage.setItem("locale", "vi-VN");

  } else if (navigator.language == "ko") {
    localStorage.setItem("locale", "ko");

  } else {
    localStorage.setItem("locale", "zh-TW");
  }

}

var lang = localStorage.getItem("locale");

const i18n = new VueI18n({
  locale: lang, // 设置当前语言环境，默认中文简体
  messages
})

export default i18n
// // 通过选项创建 VueI18n 实例并导出
// export default new VueI18n({
//   locale: "zh-TW", // 设置当前语言环境，默认中文简体
//   messages, // 设置语言环境对应信息
// });
