<template>
  <div class="LeftMenu">
    <img class="close" ref="close" @click="closeMenu" :src='require("@/assets/imgs/menu_close.png")'/>
    <div class="hide-menu" ref="hideMenu">
      <div class="item-list">
        <ul>
          <li @click="logIn(1)">
            <div class="item-list-left">
              <div class="iconImg"><img :src="img1" alt="" /></div>
              <div>{{ $t("站長推薦") }}</div>
            </div>
            <v-icon size="18" color="#c8c6c6">mdi-arrow-right</v-icon>
          </li>
          <li @click="logIn(2)">
            <div class="item-list-left">
              <div class="iconImg"><img :src="img4" alt="" /></div>
              <div>{{ $t("預約新游") }}</div>
            </div>
            <v-icon size="18" color="#c8c6c6">mdi-arrow-right</v-icon>
          </li>
          <li @click="logIn(4)">
            <div class="item-list-left">
              <div class="iconImg"><img :src="img4" alt="" /></div>
              <div>{{ $t("會員中心") }}</div>
            </div>
            <v-icon size="18" color="#c8c6c6">mdi-arrow-right</v-icon>
          </li>
          <li @click="logIn(3)">
            <div class="item-list-left">
              <div class="iconImg"><img :src="img3" alt="" /></div>
              <div>{{ $t("商務合作") }}</div>
            </div>
            <v-icon size="18" color="#c8c6c6">mdi-arrow-right</v-icon>
          </li>
        </ul>
    
      </div>
      <!-- 收藏我们 -->
      <div class="collect" @click="objData.collect = true">
        {{ $t("收藏我們") }}
      </div>
    </div>
  </div>
</template>

<script>
// 引入i18n
import i18n from "@/i18n/index";

export default {
  props: {
    objData: {
      type: Object,
    },
    userToken: {
      type: String,
    },
  },
  components: {
  
  },
  data() {
    return {
      collect: this.collect,
      img1: require("@/assets/imgs/menuImg1.png"),
      img2: require("@/assets/imgs/menuImg2.png"),
      img3: require("@/assets/imgs/menuImg3.png"),
      img4: require("@/assets/imgs/menuImg4.png"),
      img5: require("@/assets/imgs/menuImg5.png"),
      img6: require("@/assets/imgs/menuImg6.png"),
      img7: require("@/assets/imgs/menuImg7.png"),
    };
  },
  methods: {
    showMenu() {
      this.$refs.hideMenu.style.display = "block";
      this.$refs.close.style.display = "block";
    },
    closeMenu() {
      this.$refs.hideMenu.style.display = "none";
      this.$refs.close.style.display = "none";
    },
    // 跳转登录页
    logIn(id) {
      if (id == 1) {
        this.closeMenu()
        this.$nextTick(() => {
          var content2 = document.querySelector("#content2");
          var HomeAndroid = document.querySelector("#HomeAndroid");
          var scrollY = content2.offsetTop;
          HomeAndroid.scrollTo({
            top: scrollY - 50,
            behavior: "smooth",
          });
        });
      }
      if (id == 2) {
        this.$router.push("/Booking");
      }
      if (id == 3) {
        this.$router.push("/Teamwork");
      }
      if (id == 3) {
        this.$router.push("/Teamwork");
      }
      if (id == 4) {
        if (this.userToken) {
          this.$router.push("/UserInfo");
        } else {
          this.$router.push("/login");
        }
      }
      if (id == 5) {
        this.$router.push("/FAQ");
      }
      if (id == 6) {
        this.$router.push("/Recharge");
      }
      if (id == 7) {
        this.$router.push("/ActivityCenter");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.LeftMenu {
  // 左边隐藏菜单栏
  .close{
    display: none;
    position: fixed;
    left: .6rem;
    top: .8rem;
    width: .9rem;
    z-index: 999;
  }
  .hide-menu {
    position: fixed;
    display: none;
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    width: 60%;
    transition: all 0.2s;
    padding-top: 1.6rem;
    background: rgba(7, 7, 7, 0.6);
    backdrop-filter: blur(30px);
    .item-list {
      height: calc(100% - 150px);
      overflow: auto;
      ul {
        padding: 0 15px;
        li {
          margin-top: 10px;
          font-size: 14px;
          color: #c8c6c6;
          padding: 10px 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 40px;
          .item-list-left {
            display: flex;
            width: 80%;
            align-items: center;
            color: #D7E9F2;
            font-size: 0.42667rem;
            .iconImg {
              margin-right: 10px;
              img {
                display: block;
                width: 20px;
              }
            }
          }
        }
      }
    }
    // 收藏页
    .collect {
      position: absolute;
    z-index: 100;
    bottom: 0.53333rem;
    color: #D7E9F2;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    border-radius: 0.53333rem;
    font-size: 0.37333rem;
    border: 1px solid #485573;
    line-height: 0.67333rem;
    }
  }
}
</style>