<template>
  <v-app id="app">
    <router-view v-if="isRouterAlive" />
  </v-app>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 判断移动端还是pc端
    // <!--_isMobile方法-->
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  beforeCreate() {},
  created() {
    var culture = this.$getQueryString("culture");
    if (culture) {
      var lang = culture.toLocaleLowerCase();
      switch (lang) {
        case "zh-cn":
          lang = "zh-CN";
          break;
        case "zh-tw":
          lang = "zh-TW";
          break;
        case "en-us":
          lang = "en-US";
          break;
        case "ko-kr":
          lang = "ko-KR";
          break;
        case "vi-vn":
          lang = "vi-VN";
          break;
        case "th-th":
          lang = "th-TH";
          break;
        default:
          break;
      }
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
    }

    var appToken = this.$getQueryString("appToken");
    if (appToken) {
      this.$get("/api/account/getNewToken", {
        token: appToken,
        gameId: process.env.VUE_APP_API_BASE_GameId,
      }).then((res) => {
        localStorage.setItem("token", res.data.token);
      });
    }

    if (this._isMobile()) {
      var href = window.location.href.toLowerCase();
      if (
        href.lastIndexOf("recharge") ||
        href.lastIndexOf("faq") ||
        href.lastIndexOf("changepassword")
      ) {
      } else {
        // <!--移动端首页路由-->
        this.$router.replace("/");
      }
    }
  },
  mounted: function () {},
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  background-color: #F0F6F9;
}
.v-dialog {
  margin: 0;
  border-radius: 15px;
  background-color: #fff;
}
.v-dialog .d-hintImg {
  padding: 20px 10px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-dialog .d-hintImg img {
  display: block;
  width: 110px;
  height: 110px;
}
.v-dialog .d-text1 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
  color: #333333;
}
.v-dialog .d-text2 {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0;
  color: #333333;
}

.v-list .v-list-item__title {
  font-size: 18px !important;
}
.v-list .v-list-item-active {
  border-left: 2px solid #f84279 !important;
}
.v-list .v-list-item-title-active {
  color: #f84279 !important;
}
.v-card__title {
  font-size: 18px !important;
  font-weight: 800 !important;
  line-height: 1rem !important;
}
.v-card__text {
  padding: 0 40px !important;
}
.v-card__text p {
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.v-card__actions .v-btn {
  font-size: 18px !important;
  color: #fff !important;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
