<template>
  <div class="Dialog">
    <v-dialog v-model="dialog2" max-width="250">
      <div class="d-hintImg">
        <img :src="dialogImg" alt="" />
      </div>
      <div class="d-text1">{{ text1 }}</div>
      <div class="d-text2" style="font-size:14px">{{ text2 }}</div>
      <v-btn
        width="80%"
        style="background: #f84e84; color: #fff; font-size: 16px"
        height="50"
        class="rounded-lg mt-1 mb-5"
        bottom
        @click="closeDialog(skipOrder)"
        >確定</v-btn
      >
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text1: "",
      text2: "",
      dialog2: false,
      dialogImg: require("@/assets/imgs/dialogImg.png"),
      skipOrder: "",
    };
  },
  methods: {
    closeDialog(skipOrder) {
      if (skipOrder) {
        this.$router.push(skipOrder);
      }
      this.dialog2 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.Dialog {
}
</style>].