<template>
  <div class="ChangeLanguage">
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in 1" :key="i">
        <v-expansion-panel-header expand-icon="mdi-menu-down"
          ><img :src="icon" alt="" /> {{ $t("語言") }}</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-radio-group
            class="pa-0 ma-0"
            v-model="locale"
            background-color="#1F1F1F"
            hide-details=""
          >
            <v-radio
              class="my-1"
              @change="changeLanuage(item)"
              v-for="(item, i) in locales"
              :key="i"
              color="pink accent-2"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: require("@/assets/imgs/language-icon.png"),
      locale: "zh-TW", //使用i18n实例默认定义的语言
      locales: [
        {
          value: "en-US",
          text: "English",
        },
        {
          value: "zh-TW",
          text: "中文繁體",
        },
        {
          value: "vi-VN",
          text: "ViệtName",
        },
        // {
        //   value: "th-TH",
        //   text: "Thái",
        // },
        {
          value: "ko",
          text: "한국어",
        },
      ],
    };
  },
  created() {
    var lang = localStorage.getItem("locale");
    this.locale = lang;
    this.$i18n.locale = lang;
  },
  methods: {
    changeLanuage(val) {
      this.locale = val.value;
      this.$i18n.locale = val.value;
      localStorage.setItem("locale", val.value);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.ChangeLanguage {
  .v-expansion-panel::v-deep {
    .v-expansion-panel-header {
      background: rgba(7, 7, 7, 0.9);
      padding: 0 20px !important;
      font-size: 16px;
      min-height: 40px;
      color: #c8c6c6;
      & > :not(.v-expansion-panel-header__icon) {
        flex: none;
      }
      .v-expansion-panel-header__icon {
        .v-icon {
          color: #c8c6c6;
        }
      }
      img {
        display: block;
        width: 20px;
        margin-right: 10px;
      }
    }
    .v-expansion-panel-content {
      background: rgba(7, 7, 7, 0.9);
      .v-expansion-panel-content__wrap {
        padding: 0 !important;
        padding-left: 40px !important;
        .v-input {
          .v-input__control {
            .v-icon {
              color: #c8c6c6;
            }
            .v-label {
              color: #c8c6c6;
            }
          }
        }
      }
    }
  }
}
</style>